"use strict";
var protobuf = module.exports = require(18);

protobuf.build = "full";

// Parser
protobuf.tokenize         = require(34);
protobuf.parse            = require(27);
protobuf.common           = require(12);

// Configure parser
protobuf.Root._configure(protobuf.Type, protobuf.parse, protobuf.common);
